/* You can add global styles to this file, and also import other style files */

@use '@angular/material' as mat;
@import 'animate.css/animate.min';
@import "@angular/material/theming";
@import "ag-grid-community/dist/styles/ag-grid.css";
@import "ag-grid-community/dist/styles/ag-theme-alpine.css";
@import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";

@include mat-core();

$IFY-light-palette: (
  50: #e9e9e9,
  100: #c6dbdf,
  200: #a7c2c8,
  300: #86a8b0,
  400: #6d949e,
  500: #54828C,
  600: #49727b,
  700: #3a5e66,
  800: #2d4b52,
  900: #1d353b,
  A100: #c2c9db,
  A200: #9ba7c2,
  A400: #586b98,
  A700: #2c4275,
  
  contrast: (
    50: #ffe6db,
    100: #e8c5bc,
    200: #cba298,
    300: #ae7f73,
    400: #986558,
    500: #e9e9e9,
    600: #764237,
    700: #66372d,
    800: #572a26,
    900: #461d1c,
    A100: #bee0d8,
    A200: #97ccbf,
    A400: #e9e9e9,
    A700: #4a7b6a,
  )
);

$IFY-dark-palette: (
  50: #ffe6db,
  100: #e8c5bc,
  200: #cba298,
  300: #ae7f73,
  400: #986558,
  500: #824b3e,
  600: #764237,
  700: #66372d,
  800: #572a26,
  900: #461d1c,
  A100: #bee0d8,
  A200: #97ccbf,
  A400: #62a895,
  A700: #4a7b6a,
  
  contrast: (
    50: #e9e9e9,
    100: #c6dbdf,
    200: #a7c2c8,
    300: #86a8b0,
    400: #e9e9e9,
    500: #54828C,
    600: #49727b,
    700: #3a5e66,
    800: #2d4b52,
    900: #1d353b,
    A100: #c2c9db,
    A200: #9ba7c2,
    A400: #ffe6db,
    A700: #2c4275,
  )
);

$light-theme: mat-light-theme((
        color: (
                primary: mat-palette($IFY-light-palette, 500, 300, 700),
                accent: mat-palette($IFY-light-palette, A400, A100, A700),
                warn: mat-palette($mat-deep-orange),
        ),
        text: (mat-color($IFY-light-palette,900)),
));

$dark-primary: mat-palette($IFY-dark-palette, 400);
$dark-accent: mat-palette($IFY-dark-palette, A400, A100, A700);
$dark-warn: mat-palette($mat-deep-orange);


$dark-theme:  mat-dark-theme((
        color: (
                primary: $dark-primary,
                accent: $dark-accent,
                warn: $dark-warn,
        ),
        text: ( mat-color($IFY-dark-palette,50)),
));

@mixin body-theme($theme) {
  $_background: map-get($theme, background);
  $app-background-color: mat-color($_background, background);
  $app-font-color: map-get($theme,text);

  body {
    background-color: $app-background-color;
    color: $app-font-color;
  }

  @if $theme == $dark-theme {
    .logo img{
      filter:invert(100);
    }
  } @else {
    .logo img{
      filter:none;
    }
  }

  .mat-grid-tile.home-button{
    background-color: rgb(70, 70, 70);
  }

  button.home-button{
    background-color: rgb(70, 70, 70);
    color: $app-font-color;
  }

  .material-icons-two-tone.icon{
    filter: invert(43%) sepia(16%) saturate(993%) hue-rotate(325deg) brightness(96%) contrast(92%);
  }
}

@include angular-material-theme($light-theme);
@include body-theme($light-theme);

.dark-theme {
  @include angular-material-color($dark-theme);
  @include body-theme($dark-theme);
}

* {
  box-sizing: border-box;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.spacer {
  flex: 1 1 auto;
}